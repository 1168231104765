import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as Check } from "../../assets/icons/CheckSvg.svg";
import PrimaryButton from "../Generic/PrimaryButton";

const ListElement = ({ text, color }) => {
  return (
    <ListElementWrapper color={color}>
      <Check />
      <p>{text}</p>
    </ListElementWrapper>
  );
};

const SubscriptionPlanCard = ({
  plan,
  handleUpgradeSubscription,
  currentPlan,
  handleContactSales,
}) => {
  let planDetails = {
    planName: "...",
    price: ".....",
    planBg: lightThemeColors.planCardBg,
    titleColor: lightThemeColors.darkColorFaded,
    priceDescription: "....",
    subtitleColor: "",
    actionBtnText: "Action test btn",
    planFeatures: [],
  };

  const teams = {
    planName: "Teams",
    planDescription: "",
    planFeatures: ["MS Teams"],
    price: "£1.99",
    priceDescription: "For each user on the platform",
    actionBtnText: "Get started",
    priceSpan: "/user/month",
  };

  const slack = {
    planName: "Slack",
    planDescription: "",
    planFeatures: ["Slack"],
    price: "£1.99",
    priceDescription: "For each user on the platform",
    actionBtnText: "Get started",
    priceSpan: "/user/month",
  };

  const teams_slack = {
    planName: "Teams + Slack",
    planDescription: "",
    planFeatures: ["MS Teams", "Slack"],
    price: "£2.99",
    priceDescription: "For each user on the platform",
    actionBtnText: "Get started",
    priceSpan: "/user/month",
  };

  const apps = {
    planName: "Apps",
    planDescription: "",
    planFeatures: ["iOS", "Android", "Desktop"],
    price: "£7.99",
    priceDescription: "For each user on the platform",
    actionBtnText: "Get started",
    priceSpan: "/user/month",
  };

  const teams_apps = {
    planName: "Teams + Apps",
    planDescription: "",
    planFeatures: ["iOS", "Android", "Desktop", "MS Teams"],
    price: "£8.99",
    priceDescription: "For each user on the platform",
    actionBtnText: "Get started",
    priceSpan: "/user/month",
  };

  const slack_apps = {
    planName: "Slack + Apps",
    planDescription: "",
    planFeatures: ["iOS", "Android", "Desktop", "Slack"],
    price: "£8.99",
    priceDescription: "For each user on the platform",
    actionBtnText: "Get started",
    priceSpan: "/user/month",
  };

  const teams_slack_apps = {
    planName: "Teams + Slack + Apps",
    planDescription: "",
    planFeatures: ["iOS", "Android", "Desktop", "MS Teams", "Slack"],
    price: "£9.99",
    priceDescription: "For each user on the platform",
    actionBtnText: "Get started",
    priceSpan: "/user/month",
  };

  const enterprisePlan = {
    planBg: lightThemeColors.sidebar.selectedItemAccent,
    titleColor: lightThemeColors.background,
    planName: "Enterprise",
    planDescription: "",
    planFeatures: ["iOS", "Android", "Desktop", "MS Teams", "Slack", "API"],
    price: "Custom",
    priceDescription: "Unlimited tokens and API calls",
    actionBtnText: "Contact Sales",
    priceSpan: "",
  };

  const handleButtonClick = () => {
    switch (plan) {
      case "apps":
        handleUpgradeSubscription();
        break;
      case "teams":
        handleUpgradeSubscription();
        break;
      case "slack":
        handleUpgradeSubscription();
        break;
      case "teams+slack":
        handleUpgradeSubscription();
        break;
      case "team+apps":
        handleUpgradeSubscription();
        break;
      case "slack+apps":
        handleUpgradeSubscription();
        break;
      case "teams+slack+apps":
        handleUpgradeSubscription();
        break;
      case "enterprise":
        handleContactSales();
        break;
      default:
        break;
    }
  };

  switch (plan) {
    case "teams":
      planDetails = teams;
      break;
    case "slack":
      planDetails = slack;
      break;
    case "teams+slack":
      planDetails = teams_slack;
      break;
    case "team+apps":
      planDetails = teams_apps;
      break;
    case "slack+apps":
      planDetails = slack_apps;
      break;
    case "teams+slack+apps":
      planDetails = teams_slack_apps;
      break;
    case "enterprise":
      planDetails = enterprisePlan;
      break;
    case "apps":
      planDetails = apps;
      break;

    default:
      break;
  }

  return (
    <Card cardBg={planDetails.planBg}>
      <PlanTypeWrapper
        titlecolor={planDetails.titleColor}
        subtitleColor={planDetails.subtitleColor}
      >
        <h1>{planDetails.planName}</h1>
      </PlanTypeWrapper>
      <PriceWrapper>
        <Price color={planDetails.titleColor}>
          {planDetails.price}{" "}
          {planDetails.priceSpan && <span>{planDetails.priceSpan}</span>}
        </Price>
        <PriceDescription color={planDetails.titleColor}>
          {planDetails.priceDescription}
        </PriceDescription>
      </PriceWrapper>
      <PrimaryButton
        bgVariant={currentPlan ? lightThemeColors.validGreen : null}
        styled={
          plan === "enterprise"
            ? {
                width: "100%",
                padding: "16px",
                background: lightThemeColors.background,
                color: lightThemeColors.darkColor,
                fontSize: "16px",
              }
            : {
                width: "100%",
                padding: "16px",
                fontSize: "16px",
              }
        }
        icon={currentPlan ? <Check /> : null}
        text={currentPlan ? "Current Plan" : planDetails.actionBtnText}
        onClick={currentPlan ? () => {} : () => handleButtonClick()}
      />

      <Group>
        <GroupTitle color={planDetails.titleColor}>
          {plan === "individual" ? "One platform" : "Platforms"}
        </GroupTitle>
        <CheckList>
          {planDetails.planFeatures.map((item, index) => (
            <ListElement
              text={item}
              key={index}
              color={planDetails.titleColor}
            />
          ))}
        </CheckList>
      </Group>
    </Card>
  );
};

export default SubscriptionPlanCard;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 260px;
  min-width: 260px;
  min-height: 550px;
  max-height: 550px;
  gap: 30px;
  border-radius: 10px;
  padding: 30px 25px;
  background: ${(props) =>
    props.cardBg ? props.cardBg : lightThemeColors.sidebar.projectsBg};
`;
const PlanTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1 {
    color: ${(props) =>
      props.titlecolor ? props.titlecolor : lightThemeColors.darkColor};
    font-size: 20px;
    font-weight: 800;
    line-height: 40px;
    text-align: left;
  }
  p {
    color: ${(props) =>
      props.subtitleColor ? props.subtitleColor : "rgba(48, 44, 44, 0.7)"};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  min-height: 40px;
`;
const Price = styled.h1`
  color: ${(props) => (props.color ? props.color : lightThemeColors.darkColor)};
  font-size: 30px;
  font-weight: 600;
  line-height: 50px;
  text-align: left;
  span {
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
  }
`;
const PriceDescription = styled.h1`
  color: ${(props) => (props.color ? props.color : lightThemeColors.darkColor)};
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
`;
const CheckList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const ListElementWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    color: ${(props) =>
      props.color ? props.color : lightThemeColors.darkColor};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  svg {
    color: ${(props) =>
      props.color ? props.color : lightThemeColors.darkColor};
    width: 20px;
    height: 10px;
  }
`;
const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const GroupTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: ${(props) => (props.color ? props.color : lightThemeColors.darkColor)};
`;
